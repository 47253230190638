import React from "react"
import Layout from "../components/Layout"

const Cookies = () => {
  return (
    <Layout>
      <div className="container">
        <div className="text-component margin-y-lg">
          <h2 className="text-md">Política de Cookies</h2>
          <p>
            Las cookies son el medio técnico para la trazabilidad y seguimiento
            de la navegación en los Sitios Web.
          </p>
          <p>
            Son pequeños ficheros de texto que se escriben en el ordenador del
            Usuario. Este método tiene implicaciones sobre la privacidad, por lo
            que RIEGOS AZUER, S.L. informa de que podrá utilizar cookies con la
            finalidad de elaborar estadí­sticas de utilización del sitio web
            así­ como para identificar el PC del Usuario permitiendo reconocerle
            en sus próximas visitas.
          </p>
          <p>
            {" "}
            En todo caso, el usuario puede configurar su navegador para no
            permitir el uso de cookies en sus visitas al web site.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Cookies
